<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title class="title">
            <v-icon style="margin-right: 5px">message</v-icon>
            Invoice
            <v-spacer></v-spacer>
            <input
              type="text"
              v-mask="'####-##-##'"
              v-model="form.entry_date"
              placeholder="2075-01-01"
              class="date"
            />
          </v-card-title>

          <v-card-text>
            <v-form
              ref="form"
              @submit.prevent="store"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="store"
              lazy-validation
            >
              <v-layout wrap>
                <v-flex xs12 sm4>
                  <v-autocomplete
                    v-model="ledgerForm.account_head_id"
                    :items="itemsLedgerParty"
                    :search-input.sync="searchL"
                    hide-no-data
                    item-text="Name"
                    item-value="id"
                    label="Select party"
                    placeholder="Start typing to Search"
                    height="25"
                  ></v-autocomplete>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs12 sm8 text-xs-right>
                  <v-btn
                    :disabled="form.loading"
                    @click="addRow"
                    class="success"
                    outline
                    icon
                    small
                    style="margin-right: 77px"
                  >
                    <v-icon small class="success--text">add</v-icon>
                  </v-btn>
                </v-flex>
                <v-layout
                  column
                  class="rowRap"
                  :class="form.ledger.length > 4 ? 'fheight' : ''"
                >
                  <v-flex md12 style="overflow: auto">
                    <v-layout
                      row
                      wrap
                      v-for="(row, key) in form.ledger"
                      :key="key"
                    >
                      <v-flex xs12 sm5>
                        <!--<v-select :readonly="form.loading" v-model="row.ledger_head_id"-->
                        <!--:items="ledgerHeads"-->
                        <!--label="Select Ledger Head" @change="ledgerChange(row)"-->
                        <!--name="ledger_head_id" :height="25"/>-->
                        <v-autocomplete
                          v-model="form.ledger[key].ledger_head_id"
                          :items="itemsLedger[key]"
                          @focus="searchKey = key"
                          @keyup="ledgerHeadSearch"
                          hide-no-data
                          item-text="name"
                          item-value="id"
                          label="Select Ledger Head"
                          placeholder="Start typing to Search"
                          height="25"
                        ></v-autocomplete>
                      </v-flex>

                      <v-flex xs12 sm5>
                        <v-text-field
                          :readonly="form.loading"
                          v-model="row.total_amount"
                          type="number"
                          name="amount"
                          label="Total Amount"
                          :height="25"
                          @keyup="sumNetAmount(row)"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm1 text-xs-right>
                        <v-btn
                          :disabled="form.loading"
                          @click="removeElement(row)"
                          class="error"
                          outline
                          icon
                          small
                        >
                          <v-icon small class="error--text">remove</v-icon>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>

                <div
                  v-if="form.ledger && form.ledger.length"
                  style="width: 100%; text-align: right; padding-top: 10px"
                >
                  <v-chip label outline color="primary"
                    >Total Amount:
                    <strong>&nbsp;{{ journal.final_amount }}</strong></v-chip
                  >
                </div>
                <v-flex xs12>
                  <v-textarea
                    :readonly="form.loading"
                    v-model="form.narration"
                    label="Narration"
                    name="narration"
                    :error-messages="form.errors.get('narration')"
                  />
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              slot="activator"
              color="primary"
              dark
              outline
              @click="ledgerForm.dialog = true"
              :disabled="form.loading"
            >
              <v-icon small>add</v-icon> &nbsp;Ledger Head
            </v-btn>
            <v-spacer />
            <v-btn
              class="text-xs-right"
              color="secondary"
              :disabled="form.errors.any() || form.loading"
              @click="store"
              outline
              >Save
            </v-btn>
          </v-card-actions>
        </v-card>
        <!-- Add Ledger Head -->
        <v-dialog v-model="ledgerForm.dialog" max-width="500px" persistent>
          <v-card>
            <v-card-title class="primary white--text">
              <span class="title">Ledger Head Information</span>
            </v-card-title>

            <v-card-text class="has-spinner">
              <!--<spinner light="light" :loading="ledgerForm.loading"/>-->
              <v-container fluid grid-list-sm pa-0>
                <v-form
                  ref="form"
                  @submit.prevent="store"
                  @keydown.native="ledgerForm.errors.clear($event.target.name)"
                  @keyup.enter="store"
                  lazy-validation
                >
                  <v-layout wrap>
                    <v-flex xs12 sm6>
                      <v-text-field
                        v-model="ledgerForm.name"
                        name="name"
                        label="Name"
                        :height="25"
                        autocomplete="off"
                      />
                    </v-flex>
                    <v-flex xs12 sm6 d-flex>
                      <v-autocomplete
                        v-model="ledgerForm.account_head_id"
                        :items="itemsAccount"
                        :search-input.sync="searchA"
                        hide-no-data
                        item-text="Name"
                        item-value="id"
                        label="Account Group"
                        placeholder="Start typing to Search"
                        height="25"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 sm6 d-flex>
                      <v-select
                        v-model="ledgerForm.drcr"
                        name="drcr"
                        :items="drcrList"
                        label="Select Opening Balance DR/CR"
                        :height="25"
                      />
                    </v-flex>
                    <v-flex xs12 sm6>
                      <v-text-field
                        v-model="ledgerForm.opening_balance"
                        name="opening_balance"
                        label="Opening Balance"
                        :height="25"
                        type="number"
                        autocomplete="off"
                      />
                    </v-flex>
                    <v-flex xs12 sm12>
                      <v-textarea
                        v-model="ledgerForm.description"
                        name="description"
                        label="Description"
                        autocomplete="off"
                      />
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="warning" @click="ledgerForm.reset()" outline
                >Cancel</v-btn
              >
              <v-btn
                color="info"
                :disabled="ledgerForm.loading"
                @click="storeLedger"
                outline
                >Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </v-container>
  <!--<notification :notification="form.notification"/>-->
</template>

<script>
import data from "./modules/data";
import computed from "./modules/computed";
import mounted from "./modules/mounted";
import created from "./modules/created";
import methods from "./modules/methods";
import watch from "./modules/watch";

// import Spinner from '@/components/Partials/Spinner'
// import Notification from '@/components/Partials/Notification'

export default {
  name: "user",
  // components: {Spinner, Notification},
  data: () => data,
  mounted: mounted,
  computed: computed,
  methods: methods,
  created: created,
  watch: watch,
};
</script>
<style lang="scss" scoped>
.fheight {
  height: 45vh;
}

.rowRap {
  margin-left: 10px !important;
  margin-right: 75px !important;
  /*background: #ada8a830 !important;*/
  padding: 15px 15px;
  border: 1px solid #999;
}
.date {
  border: 1px solid;
  width: 155px;
  margin-right: 61px;
  padding-left: 22px;
}
</style>
