import Form from '@/library/Form';
    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();
// toDate: nd.format('YYYY-MM-DD'),
export default {
    form: new Form({
        ledger: [{
            ledger_head_id: '',
            ledger_head_name: '',
            total_amount: 0,
            tax: 0,
            net_amount: 0
        }],
        journal: '',
        entry_date: nd.format('YYYY-MM-DD'),
        fiscal_year_id: 1,
        narration: ''
    }, '/api/journal-entry/invoice'),
    journal: {
        narration: '',
        ledger_head_id: '',
        entry_date: '',
        final_amount: '',
    },
    searchA: null,
    entriesA: [],
    searchL: null,
    entriesL: [],
    ledgerForm: new Form({
        name: '',
        description: '',
        account_head_id: '',
        opening_balance:0,
        drcr:'dr',
    }, '/api/ledger-head'),

    ledgerHeads: [], /** Ledger Heads **/

    journalTypes: [], /** Journal Types **/

    accountHeads: [], /** Account Heads **/

    banks: [], /** Banks **/

    paymentMode: [],
    drcrList:[{value:'dr',text:'DR'},{value:'cr',text:'CR'}],
    searchKey: null,
    itemsLedger: [],

}
