export default {
    itemsAccount() {
        return this.entriesA.map(entryA => {
            return Object.assign({}, entryA, {Name: entryA.name})
        })
    },
    itemsLedgerParty() {
        return this.entriesL.map(entryL => {
            return Object.assign({}, entryL, {Name: entryL.name})
        })
    }
}
