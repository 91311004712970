export default {
    'form.ledger': 'sumNetAmount',
    'form.entry_date': 'setEntryDate',
    searchA(val) {
        if (!val) return;
        if (this.isLoading) return;

        this.isLoading = true;
        this.$rest.get('/api/account-head?search=' + val)
            .then(({data}) => {
                this.entriesA = data.data
            }).catch(err => {
        }).finally(() => (this.isLoading = false))
    },
    searchL(val) {
        if (!val) return;
        if (this.isLoading) return;

        this.isLoading = true;
        this.$rest.get('/api/ledger-head?search=' + val)
            .then(({data}) => {
                this.entriesL = data.data
            }).catch(err => {
        }).finally(() => (this.isLoading = false))
    },

}
