export default {
    editItem(data) {
    },

    store() {
        this.form.journal = this.journal;
        // console.log(this.form.journal);
        if(!this.journal.ledger_head_id) {
            this.$events.fire('notification',{message:'Data Validation failed.',status:'error'})
        }else if(!this.form.ledger[0].ledger_head_id) {
            this.$events.fire('notification',{message:'Data Validation Failed.',status:'error'})
        }else{
            this.form.store().then(({data}) => {
                this.form.reset();
                this.journal = '';
            });
        }
        // console.log(this.journal);
        // // console.log(this.form.Journal);
        // // this.form.store().then(() => {
        // //     this.form.reset();
        // //     this.journal = '';
        // // });
    },

    storeLedger() {
        // this.ledgerForm.account_head_id = this.ledgerForm.account_head_id.id;
        this.ledgerForm.store().then(({data}) => {
            this.getLedgerHeads(data.data.id);
        })
    },

    // Journal Types
    getJournalTypes() {
        this.$rest.get('/api/journal-type').then(({data}) => {
            this.pr_type = data.data.filter(item => item.pr_type === 'receipt').map(item => {
                return {value: item.id, text: item.name}
            });
        })
    },

    // account heads
    getAccountHeads() {
        this.$rest.get('/api/account-head').then(({data}) => {
            this.accountHeads = data.data.map(item => ({value: item.id, text: item.name}));
        })
    },

    getLedgerHeads(id) {
        this.$rest.get('/api/ledger-head').then(({data}) => {
            this.ledgerHeads = data.data.map(item => ({value: item.id, text: item.name}));
            if (![undefined, null].includes(id)) {
                let len = this.form.ledger.length;
                if (len > 0) this.form.ledger[len - 1].ledger_head_id = id;
                else this.form.ledger[0].ledger_head_id = id;
            }
        })
    },
    addRow: function () {
        if (!([undefined, null, ''].includes(this.journal.ledger_head_id))) {
            // this.form.ledger = [{ledger_head_id: null, total_amount: 0, tax: 0, net_amount: 0}];
            this.form.ledger.push({ledger_head_id: null, total_amount: 0, tax: 0, net_amount: 0});
        } else {
            this.$events.fire('notification',{message:'Select party',status:'error'})
        }
    },
    removeElement: function (row) {
        var index = this.form.ledger.indexOf(row);
        this.form.ledger.splice(index, 1);
    },

    ledgerChange(data) {
        let head = this.ledgerHeads.find(item => item.value === data.ledger_head_id);
        data.ledger_head_name = head.text;
    },

    getSerialNumber() {
        this.$rest.get('/api/payment-receipt/serial?type=receipt').then(({data}) => {
            this.form.serial_no = data.serial;
        });
    },

    lazySum(row) {
        return new Promise((resolve, reject) => {
            row.net_amount = parseFloat(row.total_amount) + parseFloat(row.tax);
            resolve(row);
        })
    },

    sumNetAmount(item) {
        if ([undefined, null].includes(item)) {
            if (typeof this.form.ledger === 'object' && this.form.ledger !== null) this.payment_mode_type.final_amount = this.form.ledger.map(it => parseFloat(it.net_amount)).reduce((x, y) => x + y, 0)
        } else {
            this.lazySum(item).then(() => {
                if (isNaN(item.net_amount)) item.net_amount = 0;
                this.journal.final_amount = this.form.ledger.map(it => parseFloat(it.net_amount)).reduce((x, y) => x + y, 0)
            })
        }
    },

    setEntryDate() {
        if (this.journal.entry_date == null) {
            let date = this.$converter.todayNP();
            this.journal.entry_date = `${date.year}-${date.month.pad(2)}-${date.day.pad(2)}`;
        }
    },
    ledgerHeadSearch(e) {
        if (this.searchKey !== null)
            this.$rest.get('/api/ledger-head?search=' + e.target.value)
                .then(({data}) => {
                    this.itemsLedger[this.searchKey] = [];
                    data.data.map(res => {
                        this.itemsLedger[this.searchKey].push({name: res.name, id: res.id});
                    });
                }).catch(err => {
            }).finally(() => (this.isLoading = false))
    }


}
