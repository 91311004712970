export default function created() {
	/** Get today's journals **/
    // this.form.get();

    // this.form.payment_mode.mode = 'cash';

    /** Get Account Heads **/
    this.getJournalTypes();

	/** Get Account Heads **/
    this.getLedgerHeads();

	/** Get Account Heads **/
    this.getAccountHeads();

    this.setEntryDate();
}
